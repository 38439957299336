$(function () {

    $.fn.ratioVisible = function() {
        let $win = $(window),
            wh = $win.height(),
            vp = {
                top: $win.scrollTop(),
                bottom: wh
            },
            $el = $(this),
            elbox = $el.offset();
        vp.bottom +=vp.top;
        elbox.bottom = elbox.top + $el.height();
        if(elbox.bottom >= vp.top && elbox.top <= vp.bottom) {
            if(elbox.bottom < vp.bottom) {
                return (elbox.bottom-vp.top) / wh;
            } else if(elbox.top > vp.top) {
                return (vp.bottom-elbox.top) / wh;
            }
            return 1;
        }
        return 0;
    };


    // Get menu as array {width: 100, active: false}

    const menu = [];

    // Header (Video)
    menu.push({
        width: 0, left:0
    })

    let left = 0;
    $('nav ul li').each(function (e) {
        const $this = $(this);
        menu.push({
            width: $this.outerWidth(), left:left
        })
        left = left + $this.outerWidth()
    })

    let lastpaginationactive = -1;

    $(window).on("orientationchange load resize scroll", function () {

        let array = [];

        let obj1 = {};
        obj1.index = 0;
        obj1.visiblePercent = $('#header').ratioVisible();
        array.push(obj1);

        let obj2 = {};
        obj2.index = 1;
        obj2.visiblePercent = $('#schneidetische').ratioVisible();
        array.push(obj2);

        let obj3 = {};
        obj3.index = 2;
        obj3.visiblePercent = $('#stromquellen').ratioVisible();
        array.push(obj3);

        let obj4 = {};
        obj4.index = 3;
        obj4.visiblePercent = $('#optionen').ratioVisible();
        array.push(obj4);

        let obj5 = {};
        obj5.index = 4;
        obj5.visiblePercent = $('#software').ratioVisible();
        array.push(obj5);

        let obj6 = {};
        obj6.index = 5;
        obj6.visiblePercent = $('#videos').ratioVisible();
        array.push(obj6);

        let biggesttemp = 0;
        let biggestindex = 0;

        for (let i = 0; i < array.length; i++) {
            if(array[i].visiblePercent > biggesttemp) {
                biggestindex = i;
                biggesttemp = array[i].visiblePercent;
            }
        }


        if(lastpaginationactive !== biggestindex) {
            const li = menu[array[biggestindex].index];
            $('header .underline').css({
                'width' : li.width+'px',
                'left' : li.left+'px',
            });
            lastpaginationactive = biggestindex;
        }
    });
});