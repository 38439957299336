$(function () {
   const key =  'AIzaSyDgiG0NGw1n3pMzz7emSlPHf1g5nTuvKDo';
   const playlistId = 'PLaCUx31rZftO6si8XOqhHzSa_bATTYxA6';
   const url = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=' + playlistId + '&key=' + key;
   let html = '';

    $.ajax({
        type: "GET",
        url: url,
        success: function(data)
        {
            data.items.forEach(function (item) {
                html = html + '<div class="col-sm-12 col-md-6 col-lg-4 paddingBottom2rem">\n' +
                    '                        <div class="paper">\n' +
                    '                            <div class="embed-responsive embed-responsive-16by9">\n' +
                    '                                <iframe class="embed-responsive-item lazy" data-src="https://www.youtube.com/embed/'+item.snippet.resourceId.videoId+'?rel=0" allowfullscreen></iframe>\n' +
                    '                            </div>\n' +
                    '                            <div class="padding05rem flex">\n' +
                    '                                <i class="icon-youtube_play"></i>\n' +
                    '                                <p>'+item.snippet.title+'</p>\n' +
                    '                            </div>\n' +
                    '                        </div>\n' +
                    '                    </div>';
            })
            $('#videos .row.row-eq-height').html(html);
            myLazyLoad.update();
        }
    });

});

