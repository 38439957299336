$(function () {
    $("input").change(function() {
        const $this = $( this );
        if ($this.val() !== "") {
            $this.addClass('hasInput');
        } else {
            $this.removeClass('hasInput');
        }
    });
    $("textarea").focusout(function() {
        const $this = $( this );
        if ($this.val() !== "") {
            $this.addClass('hasInput');
        } else {
            $this.removeClass('hasInput');
        }
    });

    $("#contact").submit(function(e) {
        e.preventDefault();
        const form = $(this);
        const loading = form.find('.loading')
        loading.removeClass('hidden');
        const success = form.find('.success')
        const error = form.find('.error')
        const url = form.attr('action')

        grecaptcha.execute('6Ldgj4kUAAAAAIBND7LwmPFoMwLIsL-DzHY2Hr5U', {action: 'Contact_Form'}).then(function(token) {
            $.ajax({
                type: "POST",
                url: url,
                data: form.serialize() + '&recaptcha=' + token, // serializes the form's elements.
                success: function(data)
                {
                    if (data.success) {
                        success.html(data.message)
                    }
                    if (data.error) {
                        let message = '';
                        for (let key in data.error) {
                            if (data.error.hasOwnProperty(key)) {
                                message = message + data.error[key] + '\n'
                            }
                        }
                        error.html(message)
                    }
                    loading.addClass('hidden');
                }
            });
        });


    })

})