const myLazyLoad = new LazyLoad({
    elements_selector: ".lazy",
    threshold: 600,
});

$(function () {
    $('[data-toggle="popover"]').popover()

    const mySwiper = new Swiper ('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            bulletElement: 'div'
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })


    $(".sliding-link").click(function(e) {
        e.preventDefault();
        const aid = $(this).attr("href");
        $('html,body').animate({scrollTop: $(aid).offset().top - 125},'slow');
    });

    $('i.icon-up').click(function (e) {
        e.preventDefault();
        $('html,body').animate({scrollTop: 0},'slow');
    })
});

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-1364477-54', 'auto');
ga('set', 'anonymizeIp', true);
ga('send', 'pageview');